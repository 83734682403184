<template>
  <fragment>
    <aside class="sidebar sidebar-default navs-rounded-all bg-custom2">
      <nav-bar />
      <!-- menu -->
      <menu-parent />
    </aside>
    <main class="main-content">
      <div class="position-relative">
        <!--Nav Start-->
        <nav-header />
        <!--Nav End-->
      </div>
      <div class="conatiner-fluid content-inner pb-5">
        <router-view :key="$route.fullPath" />
      </div>
    </main>
  </fragment>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { errorRequest } from '@helpers'
import TokenService from '@helpers/auth/token'

export default {
  name: 'AppLayout',
  computed: {
    ...mapGetters({
      userMenu: 'user/userMenu',
    }),
  },
  async mounted() {
    await this.getUserData()
    await this.loadMenu()
    await this.$children[1].initMenuParent()
    await this.getMaritalStatus()
    await this.getCountries()
    await this.getHousingTypes()
    await this.getWorkingConditions()
    await this.getJobTitles()
    await this.getGradeInstructions()
    await this.getProfessions()
    await this.getEconomicActivities()
  },
  methods: {
    ...mapActions({
      getUser: 'user/getData',
      logoutAction: 'auth/logout',
      loadMenu: 'menu/loadMenu',
      getMaritalStatus: 'helper/getMaritalStatus',
      getCountries: 'helper/getCountries',
      getHousingTypes: 'helper/getHousingTypes',
      getWorkingConditions: 'helper/getWorkingConditions',
      getJobTitles: 'helper/getJobTitles',
      getGradeInstructions: 'helper/getGradeInstructions',
      getProfessions: 'helper/getProfessions',
      getEconomicActivities: 'helper/getEconomicActivities',
    }),
    async getUserData() {
      const tokenDecode = TokenService.getLocalAccessTokenDecode()
      const user_id = tokenDecode.sub
      await this.getUser({ id: user_id })
    },
    async logout() {
      try {
        this.logoutAction()
        window.location.reload()
      } catch (e) {
        return errorRequest(e, this.$swal)
      }
    },
    async validateRoleMenu(route) {
      if (typeof this.userMenu !== 'undefined' && this.userMenu.length) {
        if (!this.userMenu.includes(route)) {
          this.$router.push({
            name: 'restriction',
          })
        }
      }
    },
  },
}
</script>
